import { useContext } from "react";
import { Input, Space, DatePicker, Select } from "antd";
import { useTranslation } from "react-i18next";
import sk_SK from "antd/es/date-picker/locale/sk_SK";
import "dayjs/locale/sk";
import { PatientContext } from "../PatientContext";
import moment from "moment";

const { TextArea } = Input;

function IdentificationCard() {
  const { t } = useTranslation();
  const { patient, handleInputChange } = useContext(PatientContext);

  return (
    <div className="IdentificationCard">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>{t("Firstname")}</label>
          <Input
            value={patient.identificationCard.firstName}
            onChange={(e) =>
              handleInputChange(
                "identificationCard",
                "firstName"
              )(e.target.value)
            }
          />
        </div>
        <div>
          <label>{t("Surname")}</label>
          <Input
            value={patient.identificationCard.surname}
            onChange={(e) =>
              handleInputChange("identificationCard", "surname")(e.target.value)
            }
          />
        </div>
        <div>
          <label>{t("Birth_date")}</label>
          <DatePicker
            onChange={(date) =>
              handleInputChange(
                "identificationCard",
                "birthDate"
              )(date.format("YYYY-MM-DD HH:mm:ss"))
            }
            style={{ width: "100%" }}
            format="DD. MM. YYYY HH:mm"
            value={
              patient.identificationCard.birthDate
                ? moment(
                    patient.identificationCard.birthDate,
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : null
            }
            locale={sk_SK}
          />
        </div>
        <div>
          <label>{t("Sex")}</label>
          <Select
            defaultValue={patient.identificationCard.sex}
            style={{ width: "100%" }}
            onChange={handleInputChange("identificationCard", "sex")}
            options={[
              { value: "man", label: t("Man") },
              { value: "woman", label: t("Woman") },
            ]}
          />
        </div>
        <div>
          <label>{t("Birth_number")}</label>
          <Input
            value={patient.identificationCard.birthNumber}
            onChange={(e) =>
              handleInputChange(
                "identificationCard",
                "birthNumber"
              )(e.target.value)
            }
          />
        </div>
        <div>
          <label>{t("Insurance_company")}</label>
          <Select
            defaultValue={patient.identificationCard.insuranceCompany}
            style={{ width: "100%" }}
            onChange={handleInputChange(
              "identificationCard",
              "insuranceCompany"
            )}
            options={[
              { value: 24, label: "DÔVERA zdravotná poisťovňa, a. s." },
              { value: 25, label: "VŠEOBECNÁ zdravotná poisťovňa, a. s." },
              { value: 27, label: "UNION zdravotná poisťovňa, a. s." },
            ]}
          />
        </div>
        <div>
          <label>{t("Address")}</label>
          <TextArea
            value={patient.identificationCard.address}
            onChange={(e) =>
              handleInputChange("identificationCard", "address")(e.target.value)
            }
          />
        </div>
        <div>
          <label>{t("Note")}</label>
          <TextArea
            value={patient.identificationCard.note}
            onChange={(e) =>
              handleInputChange("identificationCard", "note")(e.target.value)
            }
          />
        </div>
      </Space>
    </div>
  );
}

export default IdentificationCard;
