import { Space } from "antd";
import { useTranslation } from "react-i18next";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
// import { UserContext } from "../UserContext";

function Dashboard() {
  const { t } = useTranslation();
  // const user = useContext(UserContext);

  return (
    <div className="patient">
      <AppHeader title={t("Dashboard")} />
      <div className="content-wrapper">
        <Space direction="vertical" style={{ width: "100%" }}></Space>
      </div>
      <AppFooter />
    </div>
  );
}

export default Dashboard;
