import { useContext } from "react";
import { Space } from "antd";
import useTextarea from "../hooks/useTextarea";
import Textarea from "./Textarea";
import { PatientContext } from "../PatientContext";

function PhysiologicalFunctions() {
  const { patient, handleInputChange } = useContext(PatientContext);

  const textAreaItems = [
    {
      name: "sleep",
      translationKey: "Sleep",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "appetite",
      translationKey: "Appetite",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "diet",
      translationKey: "Diet",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "weight",
      translationKey: "Weight",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "urination",
      translationKey: "Urination",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "shit",
      translationKey: "Shit",
      item: useTextarea(null),
      withDrawer: true,
    },
  ];

  return (
    <div className="PhysiologicalFunctions">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {textAreaItems.map((textAreaItem) => (
          <Textarea
            key={textAreaItem.name}
            label={textAreaItem.translationKey}
            info={textAreaItem.item.showDrawer}
            value={patient.physiologicalFunctions[textAreaItem.name]}
            onChange={(e) =>
              handleInputChange(
                "physiologicalFunctions",
                textAreaItem.name
              )(e.target.value)
            }
            open={textAreaItem.item.openDrawer}
            onClose={textAreaItem.item.onDrawerClose}
            withDrawer={textAreaItem.withDrawer}
          />
        ))}
      </Space>
    </div>
  );
}

export default PhysiologicalFunctions;
