import { createContext, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PatientContext = createContext();

const PatientProvider = ({ children }) => {
  const { t } = useTranslation();

  const [patient, setPatient] = useState({
    identificationCard: {
      firstName: "",
      lastName: "",
      birthDate: null,
      sex: null,
      birthNumber: "",
      insuranceCompany: null,
      address: "",
      note: "",
    },
    currentIllness: {
      visitReason: "",
      anamnesis: null,
    },
    personalAnamnesis: {
      personalAnamnesis: "",
    },
    familyAnamnesis: {
      familyAnamnesis: "",
    },
    gynecologicalAnamnesis: {
      births: null,
      abortions: null,
      artificialAbortions: null,
      lastMenstruation: null,
      obstretricAnamnesis: "",
    },
    medicineAnamnesis: {
      usedMedicine: "",
      supplements: "",
    },
    abuses: {
      alcohol: t("Negates"),
      smoking: t("Negates"),
      coffee: t("Negates"),
      drugs: t("Negates"),
      gambling: t("Negates"),
      energizers: t("Negates"),
    },
    allergicAnamnesis: {
      allergies: "",
    },
    epidemiologicalAnamnesis: {
      animalBreeding: t("Negates"),
      contactWithInfectedPerson: t("Negates"),
      stayAbroad: t("Negates"),
    },
    socialWorkAnamnesis: {
      job: "",
      hobbies: "",
      accidents: "",
      maxEducation: "",
      living: "",
      maritalStatus: "",
      pets: "",
    },
    physiologicalFunctions: {
      sleep: "",
      appetite: "",
      diet: "",
      weight: "",
      urination: "",
      shit: "",
    },
    pediatricAnamnesis: {
      courseOfChildbirth: "",
      weightOfTheChildAtBirth: null,
      heightOfTheChildAtBirth: null,
      apgarScoreAfterBirth: null,
      psychomotorDevelopment: t("Psychomotor_development_default"),
      childBreastfed: "",
      formulaFeedsFrom: "",
      vaccination: t("Vaccination_default"),
    },
    extendedGynecologicalAnamnesis: {
      cycleRegularity: "",
      cycleLength: null,
      numberOfBleedingDays: null,
      numberOfNaturalBirths: null,
      numberOfCaesareanSections: null,
      hormonalContraceptives: "",
      menarché: null,
      menopause: null,
      gynaecologicalOperations: "",
      moreInfo: "",
    },
    ophthalmologicalAnamnesis: {
      previousEyeDeseases: "",
      wearingGlasses: "",
    },
    psychiatricAnamnesis: {
      previousPsychologicalExaminations: "",
      interests: "",
      selfAssessment: "",
      objectiveAnamnesis: "",
    },
    objectiveFinding: {
      bloodPressure: null,
      hearRate: null,
      breathingRate: null,
      spo2: null,
      bodyTemperature: null,
      weight: null,
      height: null,
      BMI: null,
    },
  });

  const handleInputChange = (objectKey, prop) => (value) => {
    setPatient((prevPatient) => ({
      ...prevPatient,
      [objectKey]: {
        ...prevPatient[objectKey],
        [prop]: value,
      },
    }));
  };

  return (
    <PatientContext.Provider value={{ patient, setPatient, handleInputChange }}>
      {children}
    </PatientContext.Provider>
  );
};

PatientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PatientContext, PatientProvider };
