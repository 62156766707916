import PropTypes from "prop-types";
import { Layout } from "antd";
import { ArrowLeftOutlined, CheckOutlined } from "@ant-design/icons";
import "../assets/styles/AppHeader.scss";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

function AppHeader({ title, leftButtonAction, action, ActionComponent }) {
  const navigate = useNavigate();

  return (
    <div className="app-header">
      <Header className="header">
        <div>
          {leftButtonAction ? (
            <ArrowLeftOutlined
              onClick={() => leftButtonAction()}
              className="header-item"
            />
          ) : null}
        </div>
        <div className="title header-item">{title}</div>
        <div className="header-item">
          {ActionComponent && <ActionComponent onClick={action} />}
        </div>
      </Header>
    </div>
  );
}

AppHeader.propTypes = {
  title: PropTypes.string.isRequired,
  leftButtonAction: PropTypes.func,
  action: PropTypes.func,
  ActionComponent: PropTypes.elementType,
};

export default AppHeader;
