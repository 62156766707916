import { useContext } from "react";
import { Space } from "antd";
import Textarea from "./Textarea";
import { PatientContext } from "../PatientContext";
import useTextarea from "../hooks/useTextarea";

function PsychiatricAnamnesis() {
  const { patient, handleInputChange } = useContext(PatientContext);

  const textAreaItems = [
    {
      name: "previousPsychologicalExaminations",
      translationKey: "previousPsychologicalExaminations",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "interests",
      translationKey: "interests",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "selfAssessment",
      translationKey: "selfAssessment",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "objectiveAnamnesis",
      translationKey: "objectiveAnamnesis",
      item: useTextarea(null),
      withDrawer: true,
    },
  ];

  return (
    <div className="PsychiatricAnamnesis">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {textAreaItems.map((textAreaItem) => (
          <Textarea
            key={textAreaItem.name}
            label={textAreaItem.translationKey}
            info={textAreaItem.item.showDrawer}
            value={
              patient.psychiatricAnamnesis &&
              patient.psychiatricAnamnesis[textAreaItem.name]
                ? patient.psychiatricAnamnesis[textAreaItem.name]
                : ""
            }
            onChange={(e) =>
              handleInputChange(
                "psychiatricAnamnesis",
                textAreaItem.name
              )(e.target.value)
            }
            open={textAreaItem.item.openDrawer}
            onClose={textAreaItem.item.onDrawerClose}
            withDrawer={textAreaItem.withDrawer}
          />
        ))}
      </Space>
    </div>
  );
}

export default PsychiatricAnamnesis;
