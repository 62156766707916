import { useState, useContext } from "react";
import { Input, Space } from "antd";
import { useTranslation } from "react-i18next";
import "dayjs/locale/sk";
import { PatientContext } from "../PatientContext";

const { TextArea } = Input;

function EpidemiologicalAnamnesis() {
  const { t } = useTranslation();
  const { patient, handleInputChange } = useContext(PatientContext);

  return (
    <div className="EpidemiologicalAnamnesis">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>{t("Contact_with_an_infected_person")} </label>
          <TextArea
            rows={4}
            value={patient.epidemiologicalAnamnesis.contactWithInfectedPerson}
            onChange={(e) =>
              handleInputChange(
                "epidemiologicalAnamnesis",
                "contactWithInfectedPerson"
              )(e.target.value)
            }
          />
        </div>
        <div>
          <label>{t("Stay_abroad")} </label>
          <TextArea
            rows={4}
            value={patient.epidemiologicalAnamnesis.stayAbroad}
            onChange={(e) =>
              handleInputChange(
                "epidemiologicalAnamnesis",
                "stayAbroad"
              )(e.target.value)
            }
          />
        </div>
        <div>
          <label>{t("Animal_breeding")} </label>
          <TextArea
            rows={4}
            value={patient.epidemiologicalAnamnesis.animalBreeding}
            onChange={(e) =>
              handleInputChange(
                "epidemiologicalAnamnesis",
                "animalBreeding"
              )(e.target.value)
            }
          />
        </div>
      </Space>
    </div>
  );
}

export default EpidemiologicalAnamnesis;
