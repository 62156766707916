import { Button, Image, Space, message } from "antd";
import { Link } from "react-router-dom";
import doctorsImage from "../assets/images/doctors_image.svg";
import googleLogo from "../assets/images/google-logo.png";
import facebookLogo from "../assets/images/facebook-logo.svg";
import { MailFilled } from "@ant-design/icons";
import "../assets/styles/Home.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  auth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "../firebase";

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onAuthenticated = () => {
    message.success(t("Sign_up_successfully"));
    navigate("/dashboard");
  };

  const handleError = (error) => {
    console.log(error);
    message.error(error.message);
  };

  const handleSignUp = async (service) => {
    try {
      if (service === "google") {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        onAuthenticated(result.user);
      } else if (service === "facebook") {
        const provider = new FacebookAuthProvider();
        const result = await signInWithPopup(auth, provider);
        onAuthenticated(result.user);
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="home">
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <Image src={doctorsImage} className="login-image" preview={false} />
        <h1 className="title">{t("Welcome_to_MyCases")}</h1>
        <p className="slogan">{t("Slogan")}</p>
        <Button
          size="large"
          className="login-button email-button text-uppercase"
          onClick={() => navigate("/signup")}
        >
          <MailFilled className="email-logo" />
          {t("Continue_with_email")}
        </Button>
        {/* <Button
          size="large"
          className="login-button facebook-button text-uppercase"
          onClick={() => handleSignUp("facebook")}
        >
          <Image src={facebookLogo} preview={false} className="facebook-logo" />
          {t("Continue_with_facebook")}
        </Button> */}
        <Button
          size="large"
          className="login-button google-button text-uppercase"
          onClick={() => handleSignUp("google")}
        >
          <Image src={googleLogo} preview={false} className="google-logo" />
          {t("Continue_with_gmail")}
        </Button>
        <Link to="/signin" className="login-link text-uppercase">
          {t("Sign_in")}
        </Link>
      </Space>
    </div>
  );
}

export default Home;
