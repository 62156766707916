import PropTypes from "prop-types";
import { Drawer, InputNumber } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

function NumericInput({
  label,
  info,
  value,
  onChange,
  open,
  onClose,
  withDrawer,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <label>
        {t(label)} {withDrawer && <InfoCircleOutlined onClick={info} />}
      </label>
      <InputNumber
        min={0}
        style={{ width: "100%" }}
        value={value}
        onChange={onChange}
      />
      {withDrawer && (
        <Drawer
          title={t(label)}
          placement="right"
          onClose={onClose}
          open={open}
        >
          <p>{t(`${label}_info`)}</p>
        </Drawer>
      )}
    </div>
  );
}

NumericInput.propTypes = {
  label: PropTypes.string.isRequired,
  info: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  withDrawer: PropTypes.bool.isRequired,
};

export default NumericInput;
