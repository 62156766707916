import { useContext } from "react";
import { Space, InputNumber } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Textarea from "./Textarea";
import NumericInput from "./NumericInput";
import { PatientContext } from "../PatientContext";
import { useTranslation } from "react-i18next";
import useTextarea from "../hooks/useTextarea";

function ObjectiveFinding() {
  const { patient, handleInputChange } = useContext(PatientContext);
  const { t } = useTranslation();

  const textAreaItems = [
    {
      name: "bloodPressure",
      translationKey: "bloodPressure",
      item: useTextarea(null),
      withDrawer: true,
    },
  ];

  const numericItems = [
    {
      name: "hearRate",
      translationKey: "hearRate",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "breathingRate",
      translationKey: "breathingRate",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "spo2",
      translationKey: "spo2",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "bodyTemperature",
      translationKey: "bodyTemperature",
      item: useTextarea(null),
      withDrawer: true,
    },
    {
      name: "weight",
      translationKey: "weight",
      item: useTextarea(null),
      withDrawer: false,
    },
    {
      name: "height",
      translationKey: "height",
      item: useTextarea(null),
      withDrawer: false,
    },
    {
      name: "BMI",
      translationKey: "BMI",
      item: useTextarea(null),
      withDrawer: false,
    },
  ];

  return (
    <div className="ObjectiveFinding">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {textAreaItems.map((textAreaItem) => (
          <Textarea
            key={textAreaItem.name}
            label={textAreaItem.translationKey}
            info={textAreaItem.item.showDrawer}
            value={
              patient.objectiveFinding &&
              patient.objectiveFinding[textAreaItem.name]
                ? patient.objectiveFinding[textAreaItem.name]
                : ""
            }
            onChange={(e) =>
              handleInputChange(
                "objectiveFinding",
                textAreaItem.name
              )(e.target.value)
            }
            open={textAreaItem.item.openDrawer}
            onClose={textAreaItem.item.onDrawerClose}
            withDrawer={textAreaItem.withDrawer}
          />
        ))}

        {numericItems.map((numericItem) => (
          <NumericInput
            key={numericItem.name}
            label={numericItem.translationKey}
            info={numericItem.item.showDrawer}
            value={
              patient.objectiveFinding &&
              patient.objectiveFinding[numericItem.name]
                ? patient.objectiveFinding[numericItem.name]
                : null
            }
            onChange={(value) =>
              handleInputChange("objectiveFinding", numericItem.name)(value)
            }
            open={numericItem.item.openDrawer}
            onClose={numericItem.item.onDrawerClose}
            withDrawer={numericItem.withDrawer}
          />
        ))}
      </Space>
    </div>
  );
}

export default ObjectiveFinding;
