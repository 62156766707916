import { useState, useContext } from "react";
import { Input, Space, Drawer } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "dayjs/locale/sk";
import { PatientContext } from "../PatientContext";

const { TextArea } = Input;

function MedicineAnamnesis() {
  const { t } = useTranslation();
  const { patient, handleInputChange } = useContext(PatientContext);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="MedicineAnamnesis">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>
            {t("Medicines_used")} <InfoCircleOutlined onClick={showDrawer} />
          </label>
          <TextArea
            rows={4}
            value={patient.medicineAnamnesis.usedMedicine}
            onChange={(e) =>
              handleInputChange(
                "medicineAnamnesis",
                "usedMedicine"
              )(e.target.value)
            }
          />
          <Drawer
            title={t("Medicines_used")}
            placement="right"
            onClose={onClose}
            open={open}
          >
            <p>{t("Medicines_used_info")}</p>
          </Drawer>
        </div>
        <div>
          <label>{t("Supplements")}</label>
          <TextArea
            rows={4}
            value={patient.medicineAnamnesis.supplements}
            onChange={(e) =>
              handleInputChange(
                "medicineAnamnesis",
                "supplements"
              )(e.target.value)
            }
          />
        </div>
      </Space>
    </div>
  );
}

export default MedicineAnamnesis;
