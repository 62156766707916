import PropTypes from "prop-types";
import { Input, Drawer } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

function Textarea({ label, info, value, onChange, open, onClose, withDrawer }) {
  const { t } = useTranslation();

  return (
    <div>
      <label>
        {t(label)} {withDrawer && <InfoCircleOutlined onClick={info} />}
      </label>
      <TextArea rows={4} value={value} onChange={onChange} />
      {withDrawer && (
        <Drawer
          title={t(label)}
          placement="right"
          onClose={onClose}
          open={open}
        >
          <p>{t(`${label}_info`)}</p>
        </Drawer>
      )}
    </div>
  );
}

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  info: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  withDrawer: PropTypes.bool.isRequired,
};

export default Textarea;
