import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth, onAuthStateChanged } from "./firebase";
import { UserContext } from "./UserContext";
import Home from "./components/Home";
import SignIn from "./components/SignIn";
import Dashboard from "./components/Dashboard";
import Account from "./components/Account";
import SignUp from "./components/SignUp";
import Patients from "./components/Patients";
import Patient from "./components/Patient";
import SplashScreen from "./components/SplashScreen";
import "dayjs/locale/sk";
import "./assets/styles/App.scss";
import { Spin } from "antd";
import { PatientProvider } from "./PatientContext";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    // Clean up subscription
    return () => {
      unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <UserContext.Provider value={user}>
      <Router>
        <div className="App">
          <RoutesComponent user={user} />
        </div>
      </Router>
    </UserContext.Provider>
  );
}

function PrivateRoute({ path, element, user, ...rest }) {
  return user
    ? { path, element, ...rest }
    : { path, element: <Navigate to="/" replace />, ...rest };
}

function RoutesComponent({ user }) {
  return useRoutes([
    PrivateRoute({ path: "/dashboard", element: <Dashboard />, user }),
    user
      ? { path: "/", element: <Navigate to="/dashboard" replace /> }
      : { path: "/", element: <Home /> },
    user
      ? { path: "/signin", element: <Navigate to="/dashboard" replace /> }
      : { path: "/signin", element: <SignIn /> },
    user
      ? { path: "/signup", element: <Navigate to="/dashboard" replace /> }
      : { path: "/signup", element: <SignUp /> },
    { path: "/splash/:screenNumber", element: <SplashScreen /> },
    PrivateRoute({ path: "/patients", element: <Patients />, user }),
    PrivateRoute({
      path: "/patients/new",
      element: (
        <PatientProvider>
          <Patient />
        </PatientProvider>
      ),
      user,
    }),
    PrivateRoute({
      path: "/patients/:patientId",
      element: (
        <PatientProvider>
          <Patient />
        </PatientProvider>
      ),
      user,
    }),
    PrivateRoute({ path: "/account", element: <Account />, user }),
  ]);
}

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  element: PropTypes.element.isRequired,
  user: PropTypes.object,
};

export default App;
