import { useContext } from "react";
import { Space } from "antd";
import useTextarea from "../hooks/useTextarea";
import Textarea from "./Textarea";
import { PatientContext } from "../PatientContext";

function SocialWorkAnamnesis() {
  const { patient, handleInputChange } = useContext(PatientContext);

  const textAreaItems = [
    {
      name: "job",
      translationKey: "Job",
      item: useTextarea(""),
      withDrawer: true,
    },
    {
      name: "hobbies",
      translationKey: "Free_time_sports_hobbies",
      item: useTextarea(""),
      withDrawer: false,
    },
    {
      name: "accidents",
      translationKey: "Work_accidents",
      item: useTextarea(""),
      withDrawer: false,
    },
    {
      name: "maxEducation",
      translationKey: "Max_education",
      item: useTextarea(""),
      withDrawer: false,
    },
    {
      name: "living",
      translationKey: "Living",
      item: useTextarea(""),
      withDrawer: true,
    },
    {
      name: "maritalStatus",
      translationKey: "Marital_status",
      item: useTextarea(""),
      withDrawer: true,
    },
    {
      name: "pets",
      translationKey: "Pets",
      item: useTextarea(""),
      withDrawer: true,
    },
  ];

  return (
    <div className="SocialWorkAnamnesis">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {textAreaItems.map((textAreaItem) => (
          <Textarea
            key={textAreaItem.name}
            label={textAreaItem.translationKey}
            info={textAreaItem.item.showDrawer}
            value={patient.socialWorkAnamnesis[textAreaItem.name]}
            onChange={(e) =>
              handleInputChange(
                "socialWorkAnamnesis",
                textAreaItem.name
              )(e.target.value)
            }
            open={textAreaItem.item.openDrawer}
            onClose={textAreaItem.item.onDrawerClose}
            withDrawer={textAreaItem.withDrawer}
          />
        ))}
      </Space>
    </div>
  );
}

export default SocialWorkAnamnesis;
