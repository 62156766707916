import { Image, Space, Row, Col } from "antd";
import splashImageTime from "../assets/images/splash-time.svg";
import splashImageKeyInfo from "../assets/images/splash-key-info.svg";
import splashImagePhotos from "../assets/images/splash-photos.svg";
import splashImageOCR from "../assets/images/splash-ocr.svg";
import "../assets/styles/SignIn.scss";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import "../assets/styles/SplashScreen.scss";

function SplashScreen() {
  const { t } = useTranslation();
  const { screenNumber } = useParams();

  const splashScreens = [
    {
      id: 1,
      image: splashImageTime,
      title: t("splash_time_title"),
      description: t("splash_time_description"),
    },
    {
      id: 2,
      image: splashImageKeyInfo,
      title: t("splash_key_info_title"),
      description: t("splash_key_info_description"),
    },
    {
      id: 3,
      image: splashImagePhotos,
      title: t("splash_photos_title"),
      description: t("splash_photos_description"),
    },
    {
      id: 4,
      image: splashImageOCR,
      title: t("splash_ocr_title"),
      description: t("splash_ocr_description"),
    },
  ];

  const numberOfScreens = splashScreens.length;

  const splashScreen = splashScreens.find(
    (splashScreen) => splashScreen.id === parseInt(screenNumber)
  );

  return (
    <div className="splash-screen">
      <div>
        <Space direction="vertical" size="large" style={{ display: "flex" }}>
          <Image src={splashScreen.image} preview={false} />
          <h1 className="title">{splashScreen.title}</h1>
          <p className="description">{splashScreen.description}</p>
        </Space>
      </div>
      <div className="navigation-wrapper">
        <Row>
          <Col span={8}>
            <div className="skip">
              <Link to="/signup">{t("Skip")}</Link>
            </div>
          </Col>
          <Col span={8}>
            <div className="bullets">
              {splashScreens.map((splashScreen) => (
                <Link to={`/splash/${splashScreen.id}`} key={splashScreen.id}>
                  <div
                    className={`bullet ${
                      splashScreen.id === parseInt(screenNumber) ? "active" : ""
                    }`}
                  ></div>
                </Link>
              ))}
            </div>
          </Col>
          <Col span={8}>
            <div className="next text-color-primary">
              {screenNumber < numberOfScreens ? (
                <Link to={`/splash/${parseInt(screenNumber) + 1}`}>
                  {t("Next")}
                </Link>
              ) : (
                <Link style={{ textWrap: "nowrap" }} to="/signup">
                  {t("Next")}
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SplashScreen;
