import { useState } from "react";
import { Button, Image, Space, Input, Flex, message } from "antd";
import doctorsImage from "../assets/images/doctors_image.svg";
import googleLogo from "../assets/images/google-logo.png";
import facebookLogo from "../assets/images/facebook-logo.svg";
import "../assets/styles/SignIn.scss";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "../firebase";

function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const onAuthenticated = (user) => {
    setUser(user);
    navigate("/dashboard");
  };

  const handleError = (error) => {
    console.log(error);
    message.error(error.message);
  };

  const handleEmailSignIn = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      onAuthenticated(result.user);
    } catch (error) {
      handleError(error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      onAuthenticated(result.user);
    } catch (error) {
      handleError(error);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(auth, provider);
      onAuthenticated(result.user);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="signin">
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <h1 className="title">{t("Sign_in")}</h1>
        <Image src={doctorsImage} className="signin-image" preview={false} />
        <div>
          <label style={{ float: "left", fontWeight: "bold" }}>
            {t("Email")}*
          </label>
          <Input
            value={email}
            onChange={handleEmailChange}
            placeholder={t("Your_email")}
            size="large"
          />
        </div>
        <div>
          <label style={{ float: "left", fontWeight: "bold" }}>
            {t("Password")}*
          </label>
          <Input
            value={password}
            onChange={handlePasswordChange}
            placeholder={t("Your_password")}
            size="large"
          />
          <div className="forgot-password-link">
            <Link to="/forgot-password">{t("Forgot_password?")}</Link>
          </div>
        </div>
        <Button
          size="large"
          className="signin-button email-button text-uppercase"
          onClick={() => handleEmailSignIn(email, password)}
        >
          {t("Sign_in")}
        </Button>

        <p>
          <b>{t("Or_continue_with")}</b>
        </p>

        {/* <Flex direction="row" justify="space-between">
          <Button
            size="large"
            className="signin-button facebook-button text-uppercase"
            onClick={handleFacebookSignIn}
          >
            <Image
              src={facebookLogo}
              preview={false}
              className="facebook-logo"
            />
            {t("Facebook")}
          </Button>
          <Button
            size="large"
            className="signin-button google-button text-uppercase"
            onClick={handleGoogleSignIn}
          >
            <Image src={googleLogo} preview={false} className="google-logo" />
            {t("Google")}
          </Button>
        </Flex> */}

        <Button
          size="large"
          className="signin-button text-uppercase"
          onClick={handleGoogleSignIn}
        >
          <Image src={googleLogo} preview={false} className="google-logo" />
          {t("Google")}
        </Button>

        <p>
          <b>
            {t("Dont_have_an_account")}&nbsp;
            <Link to="/signup">{t("Sign_up")}</Link>
          </b>
        </p>
      </Space>
    </div>
  );
}

export default SignIn;
