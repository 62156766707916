import { useState } from "react";

function useTextarea(initialValue) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [value, setValue] = useState(initialValue);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleValueChange = (e) => setValue(e.target.value);

  return {
    openDrawer,
    value,
    showDrawer,
    onDrawerClose,
    handleValueChange,
  };
}

export default useTextarea;
