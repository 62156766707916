import "../assets/styles/AppFooter.scss";
import { auth } from "../firebase";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Flex } from "antd";
import {
  AppstoreOutlined,
  UserOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

function AppFooter() {
  const { t } = useTranslation();

  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        console.log("User logged out");
      })
      .catch((error) => {
        console.error("Error during sign out:", error);
      });
  };

  return (
    <div className="app-footer">
      <Flex justify="space-around" align="center">
        <Link to="/dashboard">
          <AppstoreOutlined title={t("Dashboard")} />
        </Link>
        <Link to="/patients">
          <UnorderedListOutlined title={t("Patients")} />
        </Link>
        <Link to="/account">
          <UserOutlined title={t("My_account")} />
        </Link>
        <span onClick={signOut}>
          <LogoutOutlined title={t("Sign_out")} />
        </span>
      </Flex>
    </div>
  );
}

AppFooter.propTypes = {};

export default AppFooter;
