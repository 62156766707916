import React, { useContext, useEffect, useState } from "react";
import { Collapse, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import IdentificationCard from "./IdentificationCard";
import CurrentIllness from "./CurrentIllness";
import PersonalAnamnesis from "./PersonalAnamnesis";
import FamilyAnamnesis from "./FamilyAnamnesis";
import GynecologicalAnamnesis from "./GynecologicalAnamnesis";
import MedicineAnamnesis from "./MedicineAnamnesis";
import Abuses from "./Abuses";
import AllergicAnamnesis from "./AllergicAnamnesis";
import EpidemiologicalAnamnesis from "./EpidemiologicalAnamnesis";
import SocialWorkAnamnesis from "./SocialWorkAnamnesis";
import PhysiologicalFunctions from "./PhysiologicalFunctions";
import PediatricAnamnesis from "./PediatricAnamnesis";
import ExtendedGynecologicalAnamnesis from "./ExtendedGynecologicalAnamnesis";
import OphthalmologicalAnamnesis from "./OphthalmologicalAnamnesis";
import PsychiatricAnamnesis from "./PsychiatricAnamnesis";
import ObjectiveFinding from "./ObjectiveFinding";
import { CheckOutlined } from "@ant-design/icons";
import { UserContext } from "../UserContext";
import { firestore } from "../firebase";
import { collection, addDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { encryptData, decryptData } from "../crypto";
import { useParams, useNavigate } from "react-router-dom";
import { PatientContext } from "../PatientContext";

function Patient() {
  const { t } = useTranslation();
  const { patient, setPatient } = useContext(PatientContext);
  const user = useContext(UserContext);
  const { patientId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const savePatient = async () => {
    const patientsRef = collection(firestore, "patients");

    const patientData = {
      encryptedData: encryptData(patient, user.uid),
      doctorUID: user.uid,
    };

    if (patientId) {
      // Update existing patient document
      const patientRef = doc(firestore, "patients", patientId);
      await setDoc(patientRef, patientData)
        .then(() => {
          navigate(`/patients/${patientId}`);
          message.success(t("Patient_saved_successfully"));
        })
        .catch((error) => {
          console.error(t("Error_updating_patient"), error);
        });
    } else {
      // Add new patient document
      await addDoc(patientsRef, patientData)
        .then((docRef) => {
          navigate(`/patients/${docRef.id}`);
          message.success(t("Patient_saved_successfully"));
        })
        .catch((error) => {
          console.error(t("Error_adding_patient"), error);
        });
    }
  };

  useEffect(() => {
    if (!patientId) {
      setLoading(false);
      return;
    }

    const fetchPatient = async () => {
      const patientRef = doc(firestore, "patients", patientId);
      const patientDoc = await getDoc(patientRef);

      if (patientDoc.exists()) {
        const data = patientDoc.data();
        if (data.encryptedData && data.doctorUID === user.uid) {
          const decryptedData = decryptData(data.encryptedData, user.uid);
          if (decryptedData) {
            // Update the patient context with the decrypted data
            setPatient(decryptedData);
          }
        } else if (data.doctorUID !== user.uid) {
          message.warning(t("User_does_not_have_access_to_this_document"));
        }
      } else {
        message.warning(t("Patient_not_found"));
        navigate("/patients");
      }

      setLoading(false);
    };

    fetchPatient();
  }, [patientId, user.uid]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="patient">
      <AppHeader
        title={
          patientId
            ? `${patient.identificationCard.firstName} ${patient.identificationCard.surname}`
            : t("New_patient")
        }
        leftButtonAction={() => navigate("/patients")}
        action={savePatient}
        ActionComponent={CheckOutlined}
      />
      <div className="content-wrapper">
        <Space direction="vertical" style={{ width: "100%" }}>
          <h4>{t("Basic_anamneses")}</h4>
          <Collapse
            accordion
            collapsible="header"
            defaultActiveKey={[""]}
            items={[
              {
                key: "IdentificationCard",
                label: t("Identification_data"),
                children: <IdentificationCard />,
              },
              {
                key: "CurrentIllness",
                label: t("Current_illness"),
                children: <CurrentIllness />,
              },
              {
                key: "PersonalAnamnesis",
                label: t("Personal_anamnesis"),
                children: <PersonalAnamnesis />,
              },
              {
                key: "FamilyAnamnesis",
                label: t("Family_anamnesis"),
                children: <FamilyAnamnesis />,
              },
              {
                key: "GynecologicalAnamnesis",
                label: t("Gynecological_anamnesis"),
                children: <GynecologicalAnamnesis />,
              },
              {
                key: "MedicineAnamnesis",
                label: t("Medicine_anamnesis"),
                children: <MedicineAnamnesis />,
              },
              {
                key: "Abuses",
                label: t("Abuses"),
                children: <Abuses />,
              },
              {
                key: "AllergicAnamnesis",
                label: t("Allergic_anamnesis"),
                children: <AllergicAnamnesis />,
              },
              {
                key: "EpidemiologicalAnamnesis",
                label: t("Epidemiological_anamnesis"),
                children: <EpidemiologicalAnamnesis />,
              },
              {
                key: "SocialWorkAnamnesis",
                label: t("Social_and_work_anamnesis"),
                children: <SocialWorkAnamnesis />,
              },
              {
                key: "PhysiologicalFunctions",
                label: t("Physiological_functions"),
                children: <PhysiologicalFunctions />,
              },
            ]}
          />

          <h4>{t("Specific_anamneses")}</h4>

          <Collapse
            accordion
            collapsible="header"
            defaultActiveKey={[""]}
            items={[
              {
                key: "PediatricAnamnesis",
                label: t("Pediatric_anamnesis"),
                children: <PediatricAnamnesis />,
              },
              {
                key: "ExtendedGynecologicalAnamnesis",
                label: t("Extended_gynecological_anamnesis"),
                children: <ExtendedGynecologicalAnamnesis />,
              },
              {
                key: "OphthalmologicalAnamnesis",
                label: t("Ophthalmological_anamnesis"),
                children: <OphthalmologicalAnamnesis />,
              },
              {
                key: "PsychiatricAnamnesis",
                label: t("Psychiatric_anamnesis"),
                children: <PsychiatricAnamnesis />,
              },
              {
                key: "ObjectiveFinding",
                label: t("Objective_finding"),
                children: <ObjectiveFinding />,
              },
            ]}
          />
        </Space>
      </div>
      <AppFooter />
    </div>
  );
}

export default Patient;
