import { useContext } from "react";
import { Space, Select, InputNumber } from "antd";
import Textarea from "./Textarea";
import { PatientContext } from "../PatientContext";
import { useTranslation } from "react-i18next";
import useTextarea from "../hooks/useTextarea";

function ExtendedGynecologicalAnamnesis() {
  const { patient, handleInputChange } = useContext(PatientContext);
  const { t } = useTranslation();

  const textAreaItems = [
    {
      name: "hormonalContraceptives",
      translationKey: "hormonalContraceptives",
      item: useTextarea(null),
      withDrawer: true,
    },
  ];

  const textAreaItems2 = [
    {
      name: "gynaecologicalOperations",
      translationKey: "gynaecologicalOperations",
      item: useTextarea(null),
      withDrawer: false,
    },
    {
      name: "moreInfo",
      translationKey: "moreInfo",
      item: useTextarea(null),
      withDrawer: false,
    },
  ];

  return (
    <div className="ExtendedGynecologicalAnamnesis">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>{t("cycleRegularity")}</label>
          <Select
            defaultValue={
              patient.extendedGynecologicalAnamnesis.cycleRegularity
            }
            style={{ width: "100%" }}
            onChange={handleInputChange(
              "extendedGynecologicalAnamnesis",
              "cycleRegularity"
            )}
            options={[
              { value: "yes", label: t("yes") },
              { value: "no", label: t("no") },
            ]}
          />
        </div>
        <div>
          <label>
            {t("cycleLength")} ({t("days_count")})
          </label>
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            value={patient.extendedGynecologicalAnamnesis.cycleLength}
            onChange={(e) =>
              handleInputChange(
                "extendedGynecologicalAnamnesis",
                "cycleLength"
              )(e)
            }
          />
        </div>
        <div>
          <label>
            {t("numberOfBleedingDays")} ({t("days_count")})
          </label>
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            value={patient.extendedGynecologicalAnamnesis.numberOfBleedingDays}
            onChange={(e) =>
              handleInputChange(
                "extendedGynecologicalAnamnesis",
                "numberOfBleedingDays"
              )(e)
            }
          />
        </div>
        <div>
          <label>{t("numberOfCaesareanSections")}</label>
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            value={
              patient.extendedGynecologicalAnamnesis.numberOfCaesareanSections
            }
            onChange={(e) =>
              handleInputChange(
                "extendedGynecologicalAnamnesis",
                "numberOfCaesareanSections"
              )(e)
            }
          />
        </div>
        {textAreaItems.map((textAreaItem) => (
          <Textarea
            key={textAreaItem.name}
            label={textAreaItem.translationKey}
            info={textAreaItem.item.showDrawer}
            value={patient.extendedGynecologicalAnamnesis[textAreaItem.name]}
            onChange={(e) =>
              handleInputChange(
                "extendedGynecologicalAnamnesis",
                textAreaItem.name
              )(e.target.value)
            }
            open={textAreaItem.item.openDrawer}
            onClose={textAreaItem.item.onDrawerClose}
            withDrawer={textAreaItem.withDrawer}
          />
        ))}
        <div>
          <label>
            {t("menarché")} ({t("age")})
          </label>
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            value={patient.extendedGynecologicalAnamnesis.menarché}
            onChange={(e) =>
              handleInputChange("extendedGynecologicalAnamnesis", "menarché")(e)
            }
          />
        </div>
        <div>
          <label>
            {t("menopause")} ({t("age")})
          </label>
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            value={patient.extendedGynecologicalAnamnesis.menopause}
            onChange={(e) =>
              handleInputChange(
                "extendedGynecologicalAnamnesis",
                "menopause"
              )(e)
            }
          />
        </div>
        {textAreaItems2.map((textAreaItem) => (
          <Textarea
            key={textAreaItem.name}
            label={textAreaItem.translationKey}
            info={textAreaItem.item.showDrawer}
            value={patient.extendedGynecologicalAnamnesis[textAreaItem.name]}
            onChange={(e) =>
              handleInputChange(
                "extendedGynecologicalAnamnesis",
                textAreaItem.name
              )(e.target.value)
            }
            open={textAreaItem.item.openDrawer}
            onClose={textAreaItem.item.onDrawerClose}
            withDrawer={textAreaItem.withDrawer}
          />
        ))}
      </Space>
    </div>
  );
}

export default ExtendedGynecologicalAnamnesis;
