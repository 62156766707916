import { useState, useContext } from "react";
import { Input, Space, Drawer } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "dayjs/locale/sk";
import { PatientContext } from "../PatientContext";

const { TextArea } = Input;

function AllergicAnamnesis() {
  const { t } = useTranslation();
  const { patient, handleInputChange } = useContext(PatientContext);

  const [openAllergiesDrawer, setOpenAllergiesDrawer] = useState(false);

  const showAllergiesDrawer = () => {
    setOpenAllergiesDrawer(true);
  };

  const onAllergiesDrawerClose = () => {
    setOpenAllergiesDrawer(false);
  };

  return (
    <div className="AllergicAnamnesis">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>
            {t("Allergies")}{" "}
            <InfoCircleOutlined onClick={showAllergiesDrawer} />
          </label>
          <TextArea
            rows={4}
            value={patient.allergicAnamnesis.allergies}
            onChange={(e) =>
              handleInputChange(
                "allergicAnamnesis",
                "allergies"
              )(e.target.value)
            }
          />
          <Drawer
            title={t("Allergies")}
            placement="right"
            onClose={onAllergiesDrawerClose}
            open={openAllergiesDrawer}
          >
            <p>{t("Allergies_info")}</p>
          </Drawer>
        </div>
      </Space>
    </div>
  );
}

export default AllergicAnamnesis;
