import { useContext } from "react";
import { Space, Select, InputNumber } from "antd";
import Textarea from "./Textarea";
import { PatientContext } from "../PatientContext";
import useTextarea from "../hooks/useTextarea";
import { useTranslation } from "react-i18next";

function PediatricAnamnesis() {
  const { patient, handleInputChange } = useContext(PatientContext);
  const { t } = useTranslation();

  const textAreaItems = [
    {
      name: "psychomotorDevelopment",
      translationKey: "Psychomotor_development",
      item: useTextarea(t("Psychomotor_development_default")),
      withDrawer: true,
    },
    {
      name: "childBreastfed",
      translationKey: "Child_breastfed",
      item: useTextarea(""),
      withDrawer: false,
    },
    {
      name: "formulaFeedsFrom",
      translationKey: "Formula_feeds_from",
      item: useTextarea(""),
      withDrawer: false,
    },
    {
      name: "vaccination",
      translationKey: "Vaccination",
      item: useTextarea("Vaccination_default"),
      withDrawer: true,
    },
  ];

  return (
    <div className="PediatricAnamnesis">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>{t("Course_of_childbirth")}</label>
          <Select
            defaultValue={patient.pediatricAnamnesis.courseOfChildbirth}
            style={{ width: "100%" }}
            onChange={handleInputChange(
              "pediatricAnamnesis",
              "courseOfChildbirth"
            )}
            options={[
              { value: "spontaneous", label: t("spontaneous") },
              { value: "by_ticks", label: t("by_ticks") },
              { value: "by_vacuum_extractor", label: t("by_vacuum_extractor") },
              {
                value: "by_caesarean_section",
                label: t("by_caesarean_section"),
              },
              { value: "by_head", label: t("by_head") },
              { value: "by_pelvic_end", label: t("by_pelvic_end") },
            ]}
          />
        </div>
        <div>
          <label>{t("Weight_of_the_child_at_birth")} (g)</label>
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            value={patient.pediatricAnamnesis.weightOfTheChildAtBirth}
            onChange={(e) =>
              handleInputChange(
                "pediatricAnamnesis",
                "weightOfTheChildAtBirth"
              )(e)
            }
          />
        </div>
        <div>
          <label>{t("Height_of_the_child_at_birth")} (cm)</label>
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            step="0.1"
            value={patient.pediatricAnamnesis.heightOfTheChildAtBirth}
            onChange={(e) =>
              handleInputChange(
                "pediatricAnamnesis",
                "heightOfTheChildAtBirth"
              )(e)
            }
          />
        </div>
        <div>
          <label>{t("Apgar_score_after_birth")}</label>
          <Select
            defaultValue={patient.pediatricAnamnesis.apgarScoreAfterBirth}
            style={{ width: "100%" }}
            onChange={handleInputChange(
              "pediatricAnamnesis",
              "apgarScoreAfterBirth"
            )}
            options={[
              { value: 1, label: 1 },
              { value: 2, label: 2 },
              { value: 3, label: 3 },
              { value: 4, label: 4 },
              { value: 5, label: 5 },
              { value: 6, label: 6 },
              { value: 7, label: 7 },
              { value: 8, label: 8 },
              { value: 9, label: 9 },
              { value: 10, label: 10 },
            ]}
          />
        </div>

        {textAreaItems.map((textAreaItem) => (
          <Textarea
            key={textAreaItem.name}
            label={textAreaItem.translationKey}
            info={textAreaItem.item.showDrawer}
            value={patient.pediatricAnamnesis[textAreaItem.name]}
            onChange={(e) =>
              handleInputChange(
                "pediatricAnamnesis",
                textAreaItem.name
              )(e.target.value)
            }
            open={textAreaItem.item.openDrawer}
            onClose={textAreaItem.item.onDrawerClose}
            withDrawer={textAreaItem.withDrawer}
          />
        ))}
      </Space>
    </div>
  );
}

export default PediatricAnamnesis;
