import { useState, useContext } from "react";
import { Input, Space, Drawer } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "dayjs/locale/sk";
import { PatientContext } from "../PatientContext";

const { TextArea } = Input;

function Abuses() {
  const { t } = useTranslation();
  const { patient, handleInputChange } = useContext(PatientContext);

  const [openAlcoholDrawer, setOpenAlcoholDrawer] = useState(false);
  const [openSmokingDrawer, setOpenSmokingDrawer] = useState(false);

  const showAlcoholDrawer = () => {
    setOpenAlcoholDrawer(true);
  };

  const onAlcoholDrawerClose = () => {
    setOpenAlcoholDrawer(false);
  };

  const showSmokingDrawer = () => {
    setOpenSmokingDrawer(true);
  };

  const onSmokingDrawerClose = () => {
    setOpenSmokingDrawer(false);
  };

  return (
    <div className="Abuses">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>
            {t("Alcohol")} <InfoCircleOutlined onClick={showAlcoholDrawer} />
          </label>
          <TextArea
            rows={4}
            value={patient.abuses.alcohol}
            onChange={(e) =>
              handleInputChange("abuses", "alcohol")(e.target.value)
            }
          />
          <Drawer
            title={t("Alcohol")}
            placement="right"
            onClose={onAlcoholDrawerClose}
            open={openAlcoholDrawer}
          >
            <p>{t("Alcohol_info")}</p>
          </Drawer>
        </div>
        <div>
          <label>
            {t("Smoking")} <InfoCircleOutlined onClick={showSmokingDrawer} />
          </label>
          <TextArea
            rows={4}
            value={patient.abuses.smoking}
            onChange={(e) =>
              handleInputChange("abuses", "smoking")(e.target.value)
            }
          />
          <Drawer
            title={t("Smoking")}
            placement="right"
            onClose={onSmokingDrawerClose}
            open={openSmokingDrawer}
          >
            <p>{t("Smoking_info")}</p>
          </Drawer>
        </div>
        <div>
          <label>{t("Coffee")}</label>
          <TextArea
            rows={4}
            value={patient.abuses.coffee}
            onChange={(e) =>
              handleInputChange("abuses", "coffee")(e.target.value)
            }
          />
        </div>
        <div>
          <label>{t("Drugs")}</label>
          <TextArea
            rows={4}
            value={patient.abuses.drugs}
            onChange={(e) =>
              handleInputChange("abuses", "drugs")(e.target.value)
            }
          />
        </div>
        <div>
          <label>{t("Gambling")}</label>
          <TextArea
            rows={4}
            value={patient.abuses.gambling}
            onChange={(e) =>
              handleInputChange("abuses", "gambling")(e.target.value)
            }
          />
        </div>
        <div>
          <label>{t("Energizers")}</label>
          <TextArea
            rows={4}
            value={patient.abuses.energizers}
            onChange={(e) =>
              handleInputChange("abuses", "energizers")(e.target.value)
            }
          />
        </div>
      </Space>
    </div>
  );
}

export default Abuses;
