import { AES, enc } from "crypto-js";

const encryptData = (data, secretKey) => {
  const stringifiedData = JSON.stringify(data);
  const encryptedData = AES.encrypt(stringifiedData, secretKey).toString();
  return encryptedData;
};

const decryptData = (encryptedData, secretKey) => {
  try {
    const bytes = AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export { encryptData, decryptData };
