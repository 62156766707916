import { useState, useContext } from "react";
import { Input, Space, Select, Drawer } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "dayjs/locale/sk";
import { PatientContext } from "../PatientContext";

const { TextArea } = Input;

function CurrentIllness() {
  const { t } = useTranslation();
  const { patient, handleInputChange } = useContext(PatientContext);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="IdentificationCard">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>
            {t("Reason_for_visiting_the_doctor")}{" "}
            <InfoCircleOutlined onClick={showDrawer} />
          </label>
          <TextArea
            rows={4}
            value={patient.currentIllness.visitReason}
            onChange={(e) =>
              handleInputChange("currentIllness", "visitReason")(e.target.value)
            }
          />
          <Drawer
            title={t("Reason_for_visiting_the_doctor")}
            placement="right"
            onClose={onClose}
            open={open}
          >
            <p>{t("Visit_reason_info")}</p>
          </Drawer>
        </div>
        <div>
          <label>{t("Anamnesis")}</label>
          <Select
            defaultValue={patient.currentIllness.anamnesis}
            style={{ width: "100%" }}
            onChange={handleInputChange("currentIllness", "anamnesis")}
            options={[
              { value: "gynecological", label: t("Gynecological") },
              { value: "pediatric", label: t("Pediatric") },
              { value: "cardiological", label: t("Cardiological") },
              {
                value: "dermatovenerological",
                label: t("Dermatovenerologický"),
              },
              { value: "ophthalmological", label: t("Ophthalmological") },
              { value: "psychatrický", label: t("Psychatrický") },
            ]}
          />
        </div>
      </Space>
    </div>
  );
}

export default CurrentIllness;
