import { useContext } from "react";
import { Input, Space } from "antd";
import { useTranslation } from "react-i18next";
import "dayjs/locale/sk";
import { PatientContext } from "../PatientContext";

const { TextArea } = Input;

function FamilyAnamnesis() {
  const { t } = useTranslation();
  const { patient, handleInputChange } = useContext(PatientContext);

  return (
    <div className="FamilyAnamnesis">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>{t("Family_anamnesis")}</label>
          <TextArea
            rows={4}
            value={patient.familyAnamnesis.familyAnamnesis}
            onChange={(e) =>
              handleInputChange(
                "familyAnamnesis",
                "familyAnamnesis"
              )(e.target.value)
            }
          />
        </div>
      </Space>
    </div>
  );
}

export default FamilyAnamnesis;
