import { useState, useContext } from "react";
import { Input, Space, Drawer } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "dayjs/locale/sk";
const { TextArea } = Input;
import { PatientContext } from "../PatientContext";

function PersonalAnamnesis() {
  const { t } = useTranslation();
  const { patient, handleInputChange } = useContext(PatientContext);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className="PersonalAnamnesis">
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <label>
            {t("Personal_anamnesis")}{" "}
            <InfoCircleOutlined onClick={showDrawer} />
          </label>
          <TextArea
            rows={4}
            value={patient.personalAnamnesis.personalAnamnesis}
            onChange={(e) =>
              handleInputChange(
                "personalAnamnesis",
                "personalAnamnesis"
              )(e.target.value)
            }
          />
          <Drawer
            title={t("Personal_anamnesis")}
            placement="right"
            onClose={onClose}
            open={open}
          >
            <p>{t("Personal_anamnesis_info")}</p>
          </Drawer>
        </div>
      </Space>
    </div>
  );
}

export default PersonalAnamnesis;
