import { useState } from "react";
import { Button, Image, Space, Input, Flex, message } from "antd";
import doctorsImage from "../assets/images/doctors_image.svg";
import googleLogo from "../assets/images/google-logo.png";
import facebookLogo from "../assets/images/facebook-logo.svg";
import "../assets/styles/SignUp.scss";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
} from "../firebase";
import { validateEmail } from "../helpers";

function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleFullNameChange = (e) => setFullName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const onAuthenticated = () => {
    message.success(t("Sign_up_successfully"));
    navigate("/dashboard");
  };

  const handleError = (error) => {
    console.log(error);
    message.error(error.message);
  };

  const handleSignUp = async (service) => {
    try {
      if (service === "email") {
        if (!fullName || !email || !password || !validateEmail(email)) {
          message.error(t("Please_fill_in_all_required_fields"));
          return;
        }

        const result = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        onAuthenticated(result.user);
      } else if (service === "google") {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        onAuthenticated(result.user);
      } else if (service === "facebook") {
        const provider = new FacebookAuthProvider();
        const result = await signInWithPopup(auth, provider);
        onAuthenticated(result.user);
      } else {
        message.error(t("Please_select_a_service"));
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="signup">
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <h1 className="title">{t("Sign_up")}</h1>
        <Image src={doctorsImage} className="signup-image" preview={false} />
        <div>
          <label style={{ float: "left", fontWeight: "bold" }}>
            {t("Fullname")}*
          </label>
          <Input
            value={fullName}
            onChange={handleFullNameChange}
            placeholder={t("Your_fullname")}
            size="large"
          />
        </div>
        <div>
          <label style={{ float: "left", fontWeight: "bold" }}>
            {t("Email")}*
          </label>
          <Input
            value={email}
            onChange={handleEmailChange}
            placeholder={t("Your_email")}
            size="large"
          />
        </div>
        <div>
          <label style={{ float: "left", fontWeight: "bold" }}>
            {t("Password")}*
          </label>
          <Input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder={t("Your_password")}
            size="large"
          />
        </div>
        <Button
          size="large"
          className="signup-button email-button text-uppercase"
          onClick={() => handleSignUp("email")}
        >
          {t("Sign_up")}
        </Button>

        <p>
          <b>{t("Or_continue_with")}</b>
        </p>

        {/* <Flex direction="row" justify="space-between">
          <Button
            size="large"
            className="signup-button facebook-button text-uppercase"
            onClick={() => handleSignUp("facebook")}
          >
            <Image
              src={facebookLogo}
              preview={false}
              className="facebook-logo"
            />
            {t("Facebook")}
          </Button>
          <Button
            size="large"
            className="signup-button google-button text-uppercase"
            onClick={() => handleSignUp("google")}
          >
            <Image src={googleLogo} preview={false} className="google-logo" />
            {t("Google")}
          </Button>
        </Flex> */}

        <Button
          size="large"
          className="signup-button text-uppercase"
          onClick={() => handleSignUp("google")}
        >
          <Image src={googleLogo} preview={false} className="google-logo" />
          {t("Google")}
        </Button>

        <p>
          <b>
            {t("Already_have_an_account?")}&nbsp;
            <Link to="/signin">{t("Sign_in")}</Link>
          </b>
        </p>
      </Space>
    </div>
  );
}

export default SignUp;
