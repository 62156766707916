import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Space, Table, Modal, message } from "antd";
import {
  DeleteFilled,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import "../assets/styles/Patients.scss";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { UserContext } from "../UserContext";
import { firestore } from "../firebase";
import { decryptData } from "../crypto";

const { confirm } = Modal;

function Patients() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const [patients, setPatients] = useState([]);

  useEffect(() => {
    const fetchPatients = async () => {
      const patientsRef = collection(firestore, "patients");
      const q = query(patientsRef, where("doctorUID", "==", user.uid));
      const querySnapshot = await getDocs(q);
      const patients = querySnapshot.docs
        .map((doc) => {
          const data = doc.data();
          if (data.encryptedData) {
            const decryptedData = decryptData(data.encryptedData, user.uid);
            if (decryptedData) {
              const patient = {
                key: doc.id,
                ...decryptedData,
              };
              return patient;
            }
          }
          return null;
        })
        .filter((patient) => patient !== null);
      setPatients(patients);
    };

    fetchPatients();
  }, [user]);

  const addPatient = () => {
    navigate("/patients/new");
  };

  function deletePatient(patientId) {
    confirm({
      title: t("Do_you_want_to_delete_this_patient?"),
      icon: <ExclamationCircleOutlined />,
      content: t("This_action_cannot_be_undone"),
      onOk() {
        const patientRef = doc(firestore, "patients", patientId);
        deleteDoc(patientRef)
          .then(() => {
            setPatients((prevPatients) =>
              prevPatients.filter((patient) => patient.key !== patientId)
            );
            message.success(t("Patient_successfully_deleted"));
          })
          .catch((error) => {
            console.error(t("Error_removing_patient"), error);
          });
      },
    });
  }

  const columns = [
    {
      title: t("Patient"),
      dataIndex: ["identificationCard", "firstName"], // Access nested property
      key: "name",
      render: (text, record) => (
        <a href={`/patients/${record.key}`}>
          {record.identificationCard.firstName}{" "}
          {record.identificationCard.surname}
        </a>
      ),
    },
    {
      title: t("Options"),
      align: "right",
      render: (text, record) => (
        <Space size="middle">
          <a href="#" onClick={() => deletePatient(record.key)}>
            <DeleteFilled />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div className="patients">
      <AppHeader
        title={t("My_patients")}
        leftButtonAction={() => navigate("/dashboard")}
        action={addPatient}
        ActionComponent={PlusOutlined}
      />
      <div className="content-wrapper">
        <Table columns={columns} dataSource={patients} />
      </div>
      <AppFooter />
    </div>
  );
}

export default Patients;
