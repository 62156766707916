import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import "../assets/styles/Account.scss";
import { UserContext } from "../UserContext";
import { Flex, Image, Space } from "antd";
import {
  ArrowRightOutlined,
  CreditCardOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function Account() {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div className="account">
      <AppHeader
        title={t("My_account")}
        leftButtonAction={() => navigate("/dashboard")}
      />
      <div className="content-wrapper">
        <div className="profile-card-wrapper">
          <div className="profile-card">
            <Flex justify="space-around" align="center">
              <div className="profile-image">
                <Image src={user.reloadUserInfo.photoUrl} preview={false} />
              </div>
              <div className="profile-info">
                <Space direction="vertical">
                  <div className="profile-name">{user.displayName}</div>
                  <div className="profile-email">{user.email}</div>
                  <div>Google</div>
                </Space>
              </div>
            </Flex>
          </div>
        </div>

        <div className="account-menu">
          <div className="account-menu-item">
            <div>
              <span className="text-color-primary">
                <SnippetsOutlined />
              </span>{" "}
              <span style={{ textDecoration: "line-through" }}>
                {t("My_orders")}
              </span>{" "}
              &nbsp;🚧
            </div>
            <div className="text-color-primary">
              <ArrowRightOutlined />
            </div>
          </div>
          <div className="account-menu-item">
            <div>
              <span className="text-color-primary">
                <CreditCardOutlined />
              </span>{" "}
              <span style={{ textDecoration: "line-through" }}>
                {t("Payment_methods")}
              </span>
              &nbsp;🚧
            </div>
            <div className="text-color-primary">
              <ArrowRightOutlined />
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
}

export default Account;
